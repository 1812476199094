import { SingleNewsCard } from "./SingleNewsCard"
import { useEffect, useState } from "react";
import { getWordpressPosts } from "../../api/WorpressPosts/wordpress";
import { Button, HStack, VStack } from "@chakra-ui/react";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { SetOptionNamesToPosts } from "../../hooks/post/SetOptionNames";

const initialURL = process.env.REACT_APP_WORDPRESS_API_URL;
const categoriesURL = process.env.REACT_APP_WORDPRESS_CATEGORIES_URL;
const tagsURL = process.env.REACT_APP_WORDPRESS_TAGS_URL;
const firlds = "&_fields=id,title,categories,excerpt,date,_links.self,_links.wp:featuredmedia"

const loadingHeights = { three: ['508px', '508px', '280px'], six: ['766px', '766px', '568px'], nine: ['1282px', '1282px', '856px'] }


export const NewsList = ({ postNum = 3, categoryId = undefined, tagId = undefined }) => {
    const initialParams = { postsNum: postNum, offset: 0 }//取得投稿数の設定
    const [offsetNumState, setOffsetNumState] = useState(initialParams.postsNum);
    const [isLoading, setIsLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    const [isPrev, setIsPrev] = useState(false);
    const [isNext, setIsNext] = useState(false);
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const categoryOption = categoryId ? `&categories=${categoryId}` : '';
    const tagsOption = tagId ? `&tags=${tagId}` : '';

    let loadingHeight;
    if (postNum > 6) {
        loadingHeight = loadingHeights.nine;
    } else if (postNum > 3) {
        loadingHeight = loadingHeights.six;
    } else {
        loadingHeight = loadingHeights.three;
    }

    useEffect(() => {
        const url = `${initialURL}?per_page=${initialParams.postsNum}&offset=${initialParams.offset + firlds + categoryOption + tagsOption}`
        const urlNext = `${initialURL}?offset=${offsetNumState + categoryOption + tagsOption}&_fields=total`
        // console.log(url)
        const fetchPostsData = async (url, urlNext) => {
            const data = await getWordpressPosts(url);
            const isNextPost = await getWordpressPosts(urlNext)
            const categoriesData = await getWordpressPosts(categoriesURL);
            setCategories([...categoriesData]);
            const tagsData = await getWordpressPosts(tagsURL);
            setTags([...tagsData])
            const newPosts = SetOptionNamesToPosts(data, categoriesData, tagsData);
            // console.log(categoriesData, tagsData)
            setPosts(newPosts);
            setIsLoading(false)

            // console.log("posts", newPosts, categories)

            if (await isNextPost.length > 0) {
                setIsNext(true);
            }
            // console.log("url", url)
        }


        fetchPostsData(url, urlNext);

        // console.log('offsetNum', offsetNumState);

    }, [])

    const HandlerPrevPage = async () => {
        if (isPrev === false) { return }
        setIsLoading(true);

        const offsetNum = offsetNumState - initialParams.postsNum * 2;
        const url = `${initialURL}?per_page=${initialParams.postsNum}&offset=${offsetNum + categoryOption + tagsOption}`
        const newData = await getWordpressPosts(url);
        const newPosts = SetOptionNamesToPosts(newData, categories, tags);
        setPosts(newPosts);
        setIsNext(true);
        setOffsetNumState((prev) => prev - initialParams.postsNum);
        offsetNum > 0 ? setIsPrev(true) : setIsPrev(false);
        setIsLoading(false);


    }
    const HandlerNextPage = async () => {
        if (isNext === false) { return }
        setIsLoading(true);

        const url = `${initialURL}?per_page=${initialParams.postsNum}&offset=${offsetNumState + categoryOption + tagsOption}`
        const urlNext = `${initialURL}?offset=${offsetNumState + initialParams.postsNum}&_fields=total${categoryOption + tagsOption}`
        const newData = await getWordpressPosts(url);
        const newPosts = SetOptionNamesToPosts(newData, categories, tags);
        const isNextPost = await getWordpressPosts(urlNext);
        setPosts(newPosts);
        setIsPrev(true)
        setOffsetNumState((prev) => prev + initialParams.postsNum);
        await isNextPost.length > 0 ? setIsNext(true) : setIsNext(false);

        setIsLoading(false);

    }
    return (
        <VStack>
            {isLoading ? <VStack height={loadingHeight}><h2>Now Loading...</h2></VStack>
                : (
                    <HStack
                        flexWrap='wrap'
                        justifyContent='start'
                    >
                        {posts.map((post) => {
                            return (
                                <SingleNewsCard post={post} key={post.date} />
                            )
                        })}
                    </HStack>

                )}
            <HStack
                marginTop='30px'
                width='100%'
                height='auto'
                justifyContent='center'
                alignItems='center'
            >
                <Button onClick={HandlerPrevPage} colorScheme='green' variant='outline' leftIcon={<MdArrowBackIos />}>前へ</Button>
                <Button onClick={HandlerNextPage} colorScheme='green' variant='outline' rightIcon={<MdArrowForwardIos />}>次へ</Button>

            </HStack>
        </VStack>
    )
}

// export const NewsListTag = ({ postNum = 6, categoryId }) => {
//     const initialParams = { postsNum: postNum, offset: 0 }//取得投稿数の設定
//     const [offsetNumState, setOffsetNumState] = useState(initialParams.postsNum);
//     const [isLoading, setIsLoading] = useState(true);
//     const [posts, setPosts] = useState([]);
//     const [isPrev, setIsPrev] = useState(false);
//     const [isNext, setIsNext] = useState(false);
//     const [categories, setCategories] = useState([]);



//     useEffect(() => {
//         // setOffsetNum((prev) => prev + initialParams.postsNum);
//         const url = `${initialURL}?per_page=${initialParams.postsNum}&offset=${initialParams.offset}${firlds}&categories=${categoryId}`
//         const urlNext = `${initialURL}?offset=${offsetNumState}&categories=${categoryId}&_fields=total`
//         // console.log(url)
//         const fetchPostsData = async (url, urlNext) => {
//             const data = await getWordpressPosts(url);
//             const isNextPost = await getWordpressPosts(urlNext)
//             const categoriesData = await getWordpressPosts(categoriesURL);
//             setCategories([...categoriesData]);
//             const newPosts = SetOptionNames(data, categories);
//             // console.log(categories, newPosts)
//             setPosts(newPosts);
//             setIsLoading(false)

//             if (await isNextPost.length > 0) {
//                 setIsNext(true);
//             }
//             // console.log("url", url)
//             // console.log("posts", await posts)
//         }


//         fetchPostsData(url, urlNext);
//         // console.log('offsetNum', offsetNumState);


//     }, [])
//     const HandlerPrevPage = async () => {
//         if (isPrev === false) { return }
//         setIsLoading(true);

//         const offsetNum = offsetNumState - initialParams.postsNum * 2;
//         const url = `${initialURL}?per_page=${initialParams.postsNum}&offset=${offsetNum}&categories=${categoryId}`
//         const newData = await getWordpressPosts(url);
//         const newPosts = SetOptionNames(newData, categories);
//         setPosts(newPosts);
//         setIsNext(true);
//         setOffsetNumState((prev) => prev - initialParams.postsNum);
//         offsetNum > 0 ? setIsPrev(true) : setIsPrev(false);
//         setIsLoading(false);


//     }
//     const HandlerNextPage = async () => {
//         if (isNext === false) { return }
//         setIsLoading(true);

//         const url = `${initialURL}?per_page=${initialParams.postsNum}&offset=${offsetNumState}&categories=${categoryId}`
//         const urlNext = `${initialURL}?offset=${offsetNumState + initialParams.postsNum}&categories=${categoryId}&_fields=total`
//         const newData = await getWordpressPosts(url);
//         const newPosts = SetOptionNames(newData, categories);
//         const isNextPost = await getWordpressPosts(urlNext);
//         setPosts(newPosts);
//         setIsPrev(true)
//         setOffsetNumState((prev) => prev + initialParams.postsNum);
//         await isNextPost.length > 0 ? setIsNext(true) : setIsNext(false);

//         setIsLoading(false);

//     }
//     return (
//         <VStack>
//             {isLoading ? <VStack height={['280px', '280px', '280px']}><h2>Now Loading...</h2></VStack>
//                 : (
//                     <HStack
//                         flexWrap='wrap'
//                         justifyContent='start'
//                     >
//                         {posts.map((post) => {
//                             return (
//                                 <SingleNewsCard post={post} key={post.date} />
//                             )
//                         })}
//                     </HStack>

//                 )}
//             <HStack
//                 marginTop='30px'
//                 width='100%'
//                 height='auto'
//                 justifyContent='center'
//                 alignItems='center'
//             >
//                 <Button onClick={HandlerPrevPage} colorScheme='green' variant='outline' leftIcon={<MdArrowBackIos />}>前へ</Button>
//                 <Button onClick={HandlerNextPage} colorScheme='green' variant='outline' rightIcon={<MdArrowForwardIos />}>次へ</Button>

//             </HStack>
//         </VStack>
//     )
// }